.goods {
  position: relative;
  min-height: 1507px;
  background: #000100;
  padding: 85px 211px 125px 76px;
  &__title {
    margin: 0 0 127px 0;
  }
  span {
    font-size: 29px;
    line-height: 28px;
    color: #797979;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 280px);
    grid-template-rows: repeat(5, 210px);
    gap: 10px;
    grid-template-areas:
      '. two . text'
      'one two three text'
      'one five three four'
      '. five six four'
      '. . six .';
  }
  &__item {
    overflow: hidden;
    width: 280px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 40px rgba(255, 255, 255, 0.5);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__item-1 {
    grid-area: one;
    //animation: rotateY 4s linear infinite;
  }
  &__item-2 {
    grid-area: two;
    //animation: rotateX 4s linear infinite;
  }
  &__item-3 {
    grid-area: three;
  }
  &__item-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #a3a2a2;
    grid-area: text;
    &:hover {
      cursor: auto;
      box-shadow: none;
    }
  }
  &__item-4 {
    grid-area: four;
  }
  &__item-5 {
    grid-area: five;
  }
  &__item-6 {
    grid-area: six;
  }
  &__names {
    line-height: 35px;
    color: #797979;
    padding: 0 0 0 50px;
  }
  &__name {
    position: relative;
    list-style: decimal;
    a {
      color: #797979;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      height: 1px;
      width: 0;
      background: red;
      transition: 0.3s all;
    }
    &:hover {
      color: white;
      position: relative;
      cursor: pointer;
      a {
        color: white;
      }
      &::before {
        width: 23px;
      }
    }
    &_active {
      color: white;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -55px;
        width: 23px;
        height: 1px;
        background:#dc1313;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .goods {
    padding: 85px 211px 125px 0;
  }
}
@media screen and (max-width: 1280px) {
  .goods {
    padding: 40px 80px;
    &__title {
      margin: 0 0 80px;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 280px);
      grid-template-rows: repeat(7, 210px);
      gap: 11px;
      grid-template-areas:
      'one . .'
      'one two text'
      'three two text'
      'three four five'
      '. four five'
      '. . six'
      '. . six';
    }
  }
}
@media screen and (max-width: 1140px) {
  .goods {
    padding: 20px 50px;
    &__title {
      margin: 0 0 40px;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 280px);
      grid-template-rows: repeat(7, 210px);
      gap: 11px;
      grid-template-areas:
      'one . .'
      'one two text'
      'three two text'
      'three four .'
      'five four .'
      'five six .'
      '. six .';
    }
  }
}
@media screen and (max-width: 992px) {
  .goods {
    padding: 20px 50px;
    &__title {
      margin: 0 0 40px;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 280px);
      grid-template-rows: repeat(8, 210px);
      gap: 11px;
      grid-template-areas:
      'one .'
      'one text'
      'two text'
      'two three'
      'four three'
      'four five'
      'six five'
      'six .';
    }
    &__item-5 {
      display: none;
    }
    &__item-6 {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .goods {
    padding: 20px 50px;
    &__title {
      margin: 0 0 40px;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 280px);
      grid-template-rows: repeat(8, 210px);
      gap: 11px;
      grid-template-areas:
      'one .'
      'one text'
      'two text'
      'two .'
      'three .'
      'three .'
      'four .'
      'four .';
    }
  }
}
@media screen and (max-width: 576px) {
  .goods {
    padding: 0 30px;
    &__title {
      margin: 0 0 20px;
      font-size: 57px;
      line-height: 57px;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(1, 280px);
      grid-template-rows: repeat(10, 210px);
      gap: 11px;
      grid-template-areas:
      'one'
      'one'
      'text'
      'text'
      'two'
      'two'
      'three'
      'three'
      'four'
      'four';
    }
  }
}
@media screen and (max-width: 414px) {
  .goods {
    padding: 10px;
    &__title {
      margin: 0 0 40px;
      font-size: 42px;
      line-height: 42px;
    }
  }
}
