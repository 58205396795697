.plinth {
  position: relative;
  width: 100%;
  height: 100vh;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    padding: 100px 50px 0;
  }

  &__text {
    width: 50%;
  }

  &_filter {
    filter: brightness(10%) contrast(80%);
  }
}

@media screen and (max-width: 1024px) {
  .plinth {
    &__text {
      width: 70%;
    }
  }
}

@media screen and (max-width: 800px) {
  .plinth {
    &__content {
      padding: 100px 30px 0;
    }
    &__text {
      width: 100%;
    }
  }
}

@media screen and (max-width: 414px) {
  .plinth {
    &__content {
      padding: 100px 15px 0;
    }
  }
}
