.slider {
  transform: translateY(0px);
  transition: 2s all;
  background: red;

  img {
    height: 430px;
    filter: grayscale(100%);
    transition: 1s all;
  }

  &:hover {
    img {
      filter: grayscale(0%);
    }
  }
}
