.contacts {
  width: 100%;
  background: white;
  padding: 50px 100px 100px;
  margin: 70px 0 0 0;
  &__content {
    display: flex;
  }
  &__data {
    width: 50%;
    padding: 50px;
  }
  &__title {
    color: #3A3637;
    margin: 0 0 30px 0;
  }
  &__map {
    width: 50%;
    min-height: 400px;
    background: black;
  }
  &__list {
    color: black;
    li {
      display: flex;
      justify-content: space-between;
      text-align: right;
      margin: 0 0 20px 0;
    }
    span {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: left;
      margin: 0 50px 0 0;
    }
    a {
      color: green;
    }
  }
}

@media screen and (max-width: 1440px) {
  .contacts {
    &__content {
      flex-direction: column;
    }
    &__data {
      width: 100%;
    }
    &__map {
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .contacts {
    padding: 100px 50px;
    &__data {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .contacts {
    padding: 100px 20px 60px;
    &__data {
      font-size: 13px;
      padding: 10px;

      span {
        text-transform: lowercase;
        font-size: 13px;
        margin: 0;
      }
    }
  }
}
