.parallax {
  position: relative;
  &__group {
    width: 100%;
    height: 100vh;
  }
  &__layer-back {
    
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    &_galvanization {
      height: calc(100vh - 280px);
      background-image: url("./../img/parallax/galvan.jpg");
    }
    &_painted {
      height: calc(100vh - 365px);
      background-image: url('./../img/parallax/color.jpg');
    }
    &_stainless {
      height: calc(100vh - 365px);
      background-image: url('./../img/parallax/stainless.jpg');
    }
  }
  &__layer-base {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    opacity: .9;
    z-index: 2;
    &_galvanization {
      min-height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 75px;
    }
    &_painted, &_stainless {
      width: 100%;
      min-height: 365px;
      display: block;
      padding: 40px 0;
    }
    &_stainless {
      padding: 40px 0 40px 50px;
    
    }
  }
  &__title {
    flex: 1 0 650px;
    font-weight: 700;
    font-size: 94px;
    line-height: 125%;
    text-transform: uppercase;
    color: #101010;
    &_painted {
      text-align: center;
    }
  }
  &__subtitle {
    flex: 1 1 542px;
    font-size: 18px;
    line-height: 140%;
    color: #676767;
    text-align: center;
    margin: 0 0 0 56px;
    max-width: 540px;
    &_painted {
      margin: 0 auto;
    }
    &_stainless {
      margin: 0;
      text-align: start;
    }
  }
  &__more {
    display: block;
    font-size: 16px;
    line-height: 163%;
    text-decoration-line: underline;
    text-transform: lowercase;
    color: #DC1313;
  }
}

@media screen and (max-width: 1140px) {
  .parallax {
    &__layer-base {
      &_galvanization {
        display: block;
      }
    }
    &__subtitle {
      &_galvanization {
        margin: 0;
        text-align: start;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .parallax {
    &__title {
      font-size: 60px;
    }
  }
}
@media screen and (max-width: 576px) {
  .parallax {
    &__layer-back {
      height: calc(100vh - 290px);
    }
    &__layer-base {
      min-height: 290px;
      padding: 30px 10px;
    }
    &__title {
      font-size: 35px;
      text-align: center;
    }
    &__subtitle {
      font-size: 16px;
      max-width: 310px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
