.mounting {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 1200px;

  &__content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0 0 100px 50px;
  }

  &__block {
    background: rgba(0, 0, 0, 0.55);
    padding: 30px;
  }

  &__title {
    font-weight: 300;
  }

  &__button {
    border: 1px solid white;
    color: white;
    background: black;
    transition: 1s all;

    &:hover {
      color: black;
      background: white;
      border: 1px solid black;
    }
  }

  &_filter {
    filter: brightness(30%) contrast(80%);
  }
}

@media screen and (max-width: 1024px) {
  .mounting {
    &__content {
      width: 100%;
    }

    &__block {
      max-width: 95%;
    }
  }
}

@media screen and (max-width: 800px) {
  .mounting {
    &__content {
      padding: 0 0 15% 30px;
    }
  }
}

@media screen and (max-width: 414px) {
  .mounting {
    &__content {
      padding: 0 0 15% 15px;
    }
  }
}
