.icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: 0.6s;
  margin: 10px;
  img {
    width: 25px;
    height: 25px;
    transition: 0.1s;
    &:hover {
      width: 30px;
      height: 30px;
    }
  }
  &__cart {
    display: none;
    &_active {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -4px;
        right: -4px;
        width: 12px;
        height: 12px;
        border: 1px solid red;
        background: red;
        border-radius: 50%;
        color: red;
      }
    }
    span {
      color: white;
      font-size: 10px;
      position: absolute;
      top: -2px;
      right: -1px;
    }
    .hidden {
      display: none;
    }
  }
  &__whatsapp {
    position: relative;
  }
  &__whatsapp-link {
    display: none;
    &_active {
      display: block;
      position: absolute;
      top: 30px;
      left: -150px;
      color: white;
      background:#53a700;
      width: 150px;
      border: 1px solid green;
      border-radius: 20px 0 20px 20px;
      text-align: center;
      padding: 10px;
      transition: .4s all;
      &:hover {
        color: black;
        background:chartreuse;
      }
    }
  }
}
