.header {
  height: 70px;
  width: 1440px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  &_black {
    background: black;
  }
  &_full {
    width: 100%;
  }
  &__content {
    width: 1440px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: black;
    padding: 0 30px;
    margin: 0 auto;
  }
  &__row {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: black;
    padding: 0 30px;
  }
  &__hamburger {
    display: none;
  }
  &__logo {
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-weight: bold;
    color: white;
    border-right: 1px solid #dc1313;
    border-bottom: 1px solid #dc1313;
    padding: 0 3px 0 0;
    cursor: pointer;
    span {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transform: translateX(-100%);
      transition: transform 275ms ease;
      &::before {
        display: inline-block;
        content: attr(data-content);
        color: red;
        transform: translateX(100%);
        transition: transform 275ms ease;
      }
    }
    &:hover {
      span {
        transform: translateX(0);
        &::before {
          transform: translateX(0);
        }
      }
    }
  }
  &__link {
    color: #b6b6b6;
    text-decoration: none;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #dc1313;
      transition: 0.4s all;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .header {
    width: 100%;
    &__content {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    &__logo {
      display: none;
    }
    &__hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50px;
      width: 40px;
      cursor: text;
      padding: 10px 10px 10px 0;
      span:nth-child(odd) {
        color: white;
        font-size: 12px;
        line-height: 12px;
        border-right: 1px solid red;
        border-bottom: 3px solid #dc1313;
        padding: 0;
      }
      span:nth-child(2) {
        width: 25px;
        height: 2px;
        background: red;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    &__link {
      color: white;
    }
  }
}

@media screen and (max-width: 414px) {
  .header {
    &__row {
      padding: 0 15px;
    }
    &__phone {
      font-size: 12px;
    }
  }
}
