/* This stylesheet generated by Transfonter (https://transfonter.org) on June 26, 2017 12:11 PM */

@font-face {
	font-family: 'Gotham Pro';
	src: url('./../fonts/GothamPro-Light.eot');
	src: local('./../fonts/GothamPro-Light'), local('./../fonts/GothamPro-Light'),
		url('./../fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/GothamPro-Light.woff') format('woff'),
		url('./../fonts/GothamPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('./../fonts/GothamPro.eot');
	src: local('./../fonts/GothamPro'), local('./../fonts/GothamPro'),
		url('./../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/GothamPro.woff') format('woff'),
		url('./../fonts/GothamPro.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('./../fonts/GothamPro-Medium.eot');
	src: local('./../fonts/GothamPro-Medium'), local('./../fonts/GothamPro-Medium'),
		url('./../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/GothamPro-Medium.woff') format('woff'),
		url('./../fonts/GothamPro-Medium.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('./../fonts/GothamPro-Bold.eot');
	src: local('./../fonts/GothamPro-Bold'), local('./../fonts/GothamPro-Bold'),
		url('./../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/GothamPro-Bold.woff') format('woff'),
		url('./../fonts/GothamPro-Bold.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('./../fonts/GothamPro.eot');
	src: local('./../fonts/GothamPro'), local('./../fonts/GothamPro'),
		url('./../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/GothamPro.woff') format('woff'),
		url('./../fonts/GothamPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('./../fonts/GothamPro-Bold.eot');
	src: local('./../fonts/GothamPro-Bold'), local('./../fonts/GothamPro-Bold'),
		url('./../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/GothamPro-Bold.woff') format('woff'),
		url('./../fonts/GothamPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}