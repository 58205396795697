.coloring {
  overflow: hidden;
  position: relative;
  min-height: 800px;
  width: 100%;
  background: #000;


  &__image {
    position: relative;
    width: 100%;
    img {
      min-height: 100%;
      max-width: 100%;
    }
  }
  &__description {
    position: absolute;
    bottom: 22%;
    right: 11%;
    max-width: 545px;
  }
  &__title {
    font-weight: 700;
    font-size: 90px;
    line-height: 86px;
    margin-bottom: 30px;
  }
  &__subtitle {
    color: #B7B7B7;
    margin-bottom: 47px;
  }
}
@media screen and (max-width: 1280px) {
  .coloring {
    &__description {
      max-width: 85%;
      bottom: 3%;
      right: 9%;
    }
    &__title {
      font-size: 70px;
      line-height: 63px;
      margin-bottom: 0;
    }

    &__subtitle {
      margin-bottom: 3px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .coloring {
    &__description {
      max-width: 80%;
    }
    &__title {
      font-size: 54px;
      line-height: 50px;
    }
  }
}
@media screen and (max-width: 690px) {
  .coloring {
    min-height: 660px;
    &__image {
      img {
        max-width: 100%;
      }
    }
    &__title {
      font-size: 44px;
      line-height: 62px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 143%;
    }
  }
}
