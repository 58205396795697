.measuring {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #131514;

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;
    padding: 0 0 0 50px;
  }
}

@media screen and (max-width: 800px) {
  .measuring {
    display: flex;
    flex-direction: column;
    &__bg {
      position: relative;
      top: 0;
      left: 0;
      align-self: end;
      width: 70%;
    }
    &__content {
      width: 100%;
      padding: 0 0 0 30px;
    }
  }
}

@media screen and (max-width: 800px) {
  .measuring {
    &__content {
      padding: 0 15px;
    }
    &__button {
      margin: 0 0 20px;
    }
  }
}
