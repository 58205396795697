@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

@keyframes rotateX {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(360deg);
  }
}

//@keyframes random {
//  0% {
//    animation: rotateY 1s linear;
//  }
//  100% {
//    animation: rotateX 1s linear;
//  }
//}
