.froze {
  min-height: fit-content;
  width: 100%;
  background: #fff;
  &__wrap {
    display: flex;
    justify-content: center;
  }
  &__schema {
    flex: 1 0 50%;
    img {
      max-width: 90%;
    }
  }
  &__description {
    flex: 1 1 50%;
    padding: 180px 25px 30px 99px;
  }
  &__title {
    margin-bottom: 33px;
    span {
      font-weight: 400;
      font-size: 22px;
      line-height: 21px;
      color: #3A3637;
    }
  }
}

@media screen and (max-width: 1280px) {
  .froze {
    &__description {
      padding: 145px 25px 10px 25px;
    }
    
  }
}
@media screen and (max-width: 1024px) {
  .froze {
    &__schema {
      flex: 1 0 55%;
    }   
  
    &__description {
      flex: 1 0 35%;
    }
    &__title {
      font-size: 54px;
      line-height: 42px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .froze {
    &__wrap {
      flex-direction: column;
      justify-content: center;
    }
    &__schema {
      flex: 1 1 100%;
    }
    &__description {
      flex: 1 1 100%;
      padding: 25px 25px 35px;
    }
  }
}
@media screen and (max-width: 576px) {
  .froze {
    &__title {
      font-size: 45px;
      line-height: 40px;
      margin-bottom: 20px;
      span {
        font-size: 18px;
      }
    }
    &__subtitle {
      font-size: 16px;
      line-height: 143%;
    }
  }
}