.price {
  position: relative;
  width: 100%;
  background-image: url('./../img/price/bgprice.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 200px 150px 150px;
  z-index: 0;
  &__content {
    display: flex;
  }
  &__left {
    width: 50%;
  }
  &__logo {
    font-size: 20px;
    line-height: 19px;
    color: #DC1313;
    text-transform: uppercase;
    margin-bottom: 30px;
    span {
      font-weight: 700;
      font-size: 50px;
      line-height: 38px;
    }
  }
  &__h4 {
    font-weight: 700;
    font-size: 38px;
    line-height: 36px;
    color: #292929;
    margin-bottom: 20px;
  }
  &__description {
    font-size: 18px;
    line-height: 163%;
    color: #1E1E1E;
    margin-bottom: 30px;
  }
  &__btn {
    display: inline;
    background: #DC1313;
    font-size: 16px;
    text-align: center;
    color: #FFF;
    transition: 1s all;
    border: 3px solid white;
    padding: 15px 54px;
    &:hover {
      color: #DC1313;
      background: #FFF;
      border: 3px solid #DC1313;
      transform: scale(1.1);
    }
  }
  &__img {
    width: 50%;
    img {
      width: 100%;
    }
  }
  &__text {
    margin: 0 0 30px 0;
  }
}

@media screen and (max-width: 1024px) {
  .price {
    padding: 150px 100px;
    &__left {
      width: 100%;
    }
    &__img {
      display: none;
    }
  }
}
@media screen and (max-width: 600px) {
  .price {
    padding: 100px 50px;
  }
}

@media screen and (max-width: 414px) {
  .price {
    padding: 50px 15px;
    &__btn {
      display: inline-block;
      padding: 15px 30px;
    }
  }
}
