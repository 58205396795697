.roof {
  position: relative;
  width: 100%;
  height: 100vh;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    height: 100%;
    align-items: end;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 67%);
    padding: 0 90px 15%;

    &_bottom {
      padding: 0 90px 5%;
    }
  }
  &__text {
    max-width: 50%;
    margin: 0 0 10px;
  }

  &__button {
    align-self: end;
  }
}

@media screen and (max-width: 1140px) {
  .roof {
    &__content {
      padding: 0 30px 15%;
    }
  }
}

@media screen and (max-width: 800px) {
  .roof {
    &__content {
      padding: 0 30px 15%;
    }
  }
}

@media screen and (max-width: 414px) {
  .roof {
    &__content {
      padding: 0 15px 15%;
    }
  }
}
