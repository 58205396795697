.fence {
  position: relative;
  width: 100%;
  height: 100vh;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__title {
    margin: 0 0 20px;
  }

  &__text {
    text-align: center;
    max-width: 1000px;
    width: 80%;
  }

  &__button {
    align-self: center;
  }
}

@media screen and (max-width: 1024px) {
  .fence {
    &__title {
      margin: 0 0 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .fence {
    &__content {
      padding: 30px;
    }
    &__text {
      width: 100%;
    }
  }
}

@media screen and (max-width: 414px) {
  .fence {
    &__content {
      padding: 15px;
    }
  }
}
