.first {
  position: relative;
  width: 100%;
  &__row {
    width: 100%;
    background-image: url('./../img/first/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 186px 103px 116px 135px;
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__utp {
    min-width: 540px;
  }
  &__title {
    display: inline;
    color: white;
    font-size: 48px;
    line-height: 43px;
    font-weight: bold;

    span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
      color: #797979;
    }
  }
  &__image {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .red-line {
    width: 47px;
    height: 2px;
    margin: 0 0 35px 0;
    background: #dc1313;
  }
  &__title {
    line-height: 52px;
    &_block {
      display: block;
      margin: 0 0 90px 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .first {
    &__row {
      padding: 186px 50px 116px 80px;
    }
    &__utp {
      min-width: 350px;
      &__title {
        font-size: 37px;
        line-height: 43px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .first {
    &__row {
      padding: 150px 50px 110px;
    }
    &__content {
      flex-direction: column;
    }
    &__utp {
      order: 2;
    }
    &__image {
      order: 1;
      text-align: center;
      width: 80%;
    }
  }
}

@media screen and (max-width: 576px) {
  .first {
    &__image {
      width: 100%;
    }
    &__row {
      padding: 100px 30px 70px;
    }
    &__utp {
      min-width: 100%;
    }
    &__title {
      font-size: 27px;
      line-height: 37px;
      margin: 0 0 60px;
    }
  }
}
