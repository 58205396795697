@import 'https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Roboto&display=swap';

.points {
  display: flex;
  justify-content: flex-end;
  width: 200px;
  font-family: 'Roboto', sans-serif;
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translate(calc(-50% + 820px), calc(-50% + 70px));
  list-style-type: none;
  color: #a4a4a4;
  background: linear-gradient(270deg, #18191C 0%, rgba(0, 0, 0, 0) 77.83%);
  padding: 40px 0;
  z-index: 3;
  li {
		position: relative;
    margin: 0 0 60px 0;

    &:last-of-type {
      margin: 0;
    }
  }

  &__item {
    color: white;
    transition: .4s all;
    padding: 20px 38px 20px 6px;
    &:hover {
      color: red;
      cursor: pointer;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: -18px;
      width: 13px;
      height: 13px;
      border: 1px solid #A4A4A4;
      border-radius: 50%;
    }
  }
}

.active {
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 5px;
		left: -15px;
		width: 7px;
		height: 7px;
		background: red;
		border-radius: 50%;
	}
}

@media screen and (max-width: 1400px) {
  .points {
    right: 0;
    transform: translate(0, -50%);
  }
}

@media screen and (max-width: 992px) {
  .points {
    width: 140px;
		li {
			margin: 0 0 30px 0;
			opacity: .4;
		}
    &__item {
      font-size: 16px;
      padding: 20px 25px 20px 6px;
      &:hover {
        color: #a4a4a4;
        cursor: text;
      }
      &::before {
        top: 4px;
        left: -18px;
      }
    }
  }

  .active {
    &::after {
      top: 7px;
    }
  }
}
@media screen and (max-width: 414px) {
  .points {
    width: 110px;

    &__item {
      padding: 10px 10px 6px;
    }
  }

}
