.btn {
  width: 197px;
  height: 44px;
  background: rgba(255, 255, 255, 0.09);
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;
  border: none;
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all;
  padding: 2px 0 0 0;
  &:hover {
    border: none;
    border-top: 1px solid red;
    border-right: 1px solid red;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
  }
}

@media screen and (max-width: 992px) {
  .btn {
    &:visited {
      border: none;
      border-bottom: 1px solid red;
      border-left: 1px solid red;
    }
  }
}