* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
.body {
	font-family: 'Gotham Pro';
	font-size: 18px;
	background: #fdfcfb;
}
h1 {
	font-size: 42px;
  font-weight: 900;
	color: white;
}
a {
	text-decoration: none;
}

.text-fish {
	background: rgb(39 40 44);
}

.anchor {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 1px;
	height: 70px;
	background: transparent;
}

section {
	overflow: hidden;
	position: relative;
}

.section {
	&_bottom {
		margin: 0 0 20px;
	}
}

p {
		font-size: 22px;
		font-weight: 300;
		line-height: 30px;
}
