.container {
	width: 1440px;
	height: 100%;
	margin: 0 auto;
}

@media screen and (max-width: 1440px) {
	.container {
		width: 100%;
	}
}
